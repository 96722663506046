import {React} from "react";

const Cv=()=>{

    return(
        <div className='flex-grow max-w-screen-xl mx-auto'>
            <p>Cv</p>
        </div>
    );

}

export default Cv;