const translations = {
    EN: {
      navBar_1: "About me",
      navBar_2: "Portfolio",
      navBar_3: "Resume",
      navBar_4: "Contact",
      main_caption: "Hi, I'm Santiago - Welcome!!!",
      main_description: "My name is Santiago, and I am a Full-Stack web developer. I build either the user interaction part (frontend) and the part that processes and stores the data (backend). Previously, I've worked as an electronics technician and then I began studying electronic engineering, although I finally ended up abandoning it. But in both careers I've learned the fundamental concepts of computational logic and digital electronics, which allowed me to perfectly learn the operation of a computer from its most fundamental logical aspects. I am interested in projects in which I can contribute, where I can learn and grow professionally. I invite you to visit my portfolio so you can learn more about my work. If you are interested in hiring me, send me a message and I will analyze your proposal.",
      main_second_caption: "My skills",
      contact_caption: "How to contact me",
      contact_description:"If you wish to send me a message, please fill up the next form and I will reply shortly.",
      contact_button_text:"Send",
      contact_form_name:"Name",
      contact_form_email:"Email",
      contact_form_comment:"Comment",
      contact_modal_message_ok:"Thank you for your message. We will contact you shortly.",
      contact_modal_message_failed:"There was an error when sending message. Try again later.",
      contact_modal_message_noReply:"There was no response from server. Try again later",
      contact_modal_message_button:"Ok",
      portfolio_main_caption: "My portfolio",
      portfolio_caption1: "This website",
      portfolio_text11: "If you want to know how this website is built, I'll show it to you. I don't keep anything.",
      portfolio_text12: "Here I use these technologies:",
      portfolio_text13: "Source code can be seen",
      portfolio_text14: "here",
      portfolio_caption2: "Karate Dojo",
      portfolio_text21: "Static website for a Karate dojo. The details of this academy are fantasy, so don't even think about signing up!!!",
      portfolio_text22: "These are the technologies I use on this site:",
      portfolio_text23: "And if you want to see the source code, you can click ",
      portfolio_text24: "here",
      portfolio_caption3: "Fake parcel company",
      portfolio_text31: "If you have to send a package and you know its measurements and declared value, you enter all that information on the page and... Voilá!!! The site will tell you how much you have to pay for shipping. You can even pay with Mercado Pago. However, since the site is a test site... do not enter your real information when paying!!!",
      portfolio_text32: "By the way, the site was built using these technologies:",
      portfolio_text33: "And if you want to see the source code, you can click ",
      portfolio_text34: "here",
      portfolio_caption4: "Ecommerce site with React and Firebase",
      portfolio_text41: "Compumaxx, an IT supplies company that only exists in our code repository, has this e-commerce site based on React.JS and uses a Firebase-based database.",
      portfolio_text42: "These are the technologies I use on this site:",
      portfolio_text43: "Compumaxx offers ",
      portfolio_text44: "here",
      portfolio_text45: " its source code.",
      portfolio_caption5: "Ecommerce site from the server",
      portfolio_text51: "Another example of a simple but secure eCommerce site. Its design is so minimalist that it has no decorative style. However, anyone who wants to access it must register with a username and password. It is based on Express.JS and its data is stored in a database made with MongoDB. It also has an interactive chat between customers and administrators. It also uses cookies.",
      portfolio_text52: "To make it work, all these technologies were used:",
      portfolio_text53: "If you are curious to know what the code looks like, just click ",
      portfolio_text54: "here"
    },
    ES: {
      navBar_1: "Sobre mí",
      navBar_2: "Portfolio",
      navBar_3: "CV",
      navBar_4: "Contacto",
      main_caption: "Hola, soy Santiago - Bienvenido",
      main_description: "Mi nombre es Santiago, y soy desarrollador web Full-Stack. Construyo tanto la parte de interacción con el usuario (frontend) como la parte que procesa y almacena los datos (backend). Anteriormente me formé como técnico en electrónica y luego comencé a estudiar la carrera de ingeniería electrónica, aunque finalmente la terminé abandonando. Pero en ambas carreras aprendí los conceptos fundamentales de lógica computacional y electrónica digital, lo que me permitió aprender a la perfección el funcionamiento de una computadora desde sus aspectos lógicos más fundamentales. Me interesan los proyectos en los que pueda contribuir, en donde pueda aprender y crecer profesionalmente. Te invito a visitar mi portfolio para que conozcas más de mi trabajo. Si tienes interés en contratarme, envíame un mensaje y analizaré tu propuesta.",
      main_second_caption: "Mis habilidades",
      contact_caption: "Cómo contactarme",
      contact_description:"Si desea contactarme, puede llenar el formulario debajo y le contestraré a la brevedad.",
      contact_button_text:"Enviar",
      contact_form_name:"Nombre",
      contact_form_email:"Correo electrónico",
      contact_form_comment:"Comentario",
      contact_modal_message_ok:"Gracias por tu mensaje. Nos comunicaremos contigo a la brevedad.",
      contact_modal_message_failed:"Hubo un error al enviar el mensaje. Intenta nuevamente más tarde.",
      contact_modal_message_noReply:"No hubo respuesta del servidor. Inténtelo más tarde.",
      contact_modal_message_button:"Aceptar",
      portfolio_main_caption: "Mi portfolio",
      portfolio_caption1: "Esta página web",
      portfolio_text11: "Si quieres conoces cómo está construída está página web, te la muestro. No me guardo nada.",
      portfolio_text12: "Aquí empleo estas tecnologías:",
      portfolio_text13: "Puedes ver el código fuente",
      portfolio_text14: "aquí",
      portfolio_caption2: "Dojo de Karate",
      portfolio_text21: "Página web estática de un dojo de Karate. Los datos de esta academia son de fantasía, así que ni se te ocurra inscribirte!!!",
      portfolio_text22: "Estas son las tecnologías que uso en este sitio:",
      portfolio_text23: "Y si quieres ver el código fuente, puedes hacer clic ",
      portfolio_text24: "aquí",
      portfolio_caption3: "Empresa de paquetería Fake",
      portfolio_text31: "Si tienes que enviar una encomienda y conoces sus medidas y su valor declarado, ingresa todos esos datos en la página y...Voilá!!! El sitio te dirá cuánto tienes que pagar de envío. Puedes incluso pagar con Mercado Pago. Eso sí, como el sitio es de prueba...No introduzcas tus datos verdaderos a la hora de pagar!!!",
      portfolio_text32: "Por cierto, el sitio se construyó con estas tecnologías:",
      portfolio_text33: "Y si quieres ver el código fuente, puedes hacer clic ",
      portfolio_text34: "aquí",
      portfolio_caption4: "Sitio de Ecommerce con React y Firebase",
      portfolio_text41: "Compumaxx, una empresa de insumos de informática que sólo existe en nuestro repositorio de código, tiene este sitio de comercio electrónico basado en React.JS y utiliza una base de datos basada en Firebase.",
      portfolio_text42: "Estas son las tecnologías que uso en este sitio:",
      portfolio_text43: "Compumaxx ofrece ",
      portfolio_text44: "aquí",
      portfolio_text45: " su código fuente.",
      portfolio_caption5: "Sitio de Ecommerce desde el servidor",
      portfolio_text51: "Otro ejemplo de sitio de Ecommerce simple...pero seguro. Su diseño es tan minimalista que no tiene ningún estilo decorativo. Pero en cambio, quien desee acceder al mismo deberá registrarse con usuario y contraseña. Está basado en Express.JS y sus datos se almacenan en una base de datos hecha con MongoDB. También cuenta con un chat interactivo entre clientes y administradores. Además utiliza cookies ",
      portfolio_text52: "Para hacerlo funcionar, se emplearon todas estas tecnologías:",
      portfolio_text53: "Si tienes curiosidad por saber cómo es el código, sólo haz clic ",
      portfolio_text54: "aquí"
    },
    PT: {
      navBar_1: "Sobre mim",
      navBar_2: "Portafolio",
      navBar_3: "Curriculo",
      navBar_4: "Fale comigo",
      main_caption: "Oi, sou Santiago - Bem-vindo ao meu website!!!",
      main_description: "Meu nome é Santiago e sou desenvolvedor web Full-Stack. Eu construo a parte de interação do usuário (frontend) e também a parte que processa e armazena os dados (backend). Anteriormente, me formei como técnico em eletrônica e depois comecei a estudar engenharia eletrônica, mas acabei abandonando. Mas em ambas as carreiras aprendi os conceitos fundamentais de lógica computacional e de electrónica digital, o que me permitiu aprender perfeitamente o funcionamento de um computador desde os seus aspectos lógicos mais fundamentais. Tenho interesse em formar parte de projetos nos quais possa contribuir, onde possa aprender e crescer profissionalmente. Convido você a visitar meu portfólio para conhecer mais sobre o meu trabalho. Caso tenha interesse em me contratar, me envie uma mensagem e analisarei a sua proposta.",
      main_second_caption: "Minhas habilidades",
      contact_caption: "Fale comigo",
      contact_description:"Se quiser falar comigo, por favor preencha o seguinte formulario e lhe contatarei em breve.",
      contact_button_text:"Enviar",
      contact_form_name:"Nome",
      contact_form_email:"Correio eletrônico",
      contact_form_comment:"Comentário",
      contact_modal_message_ok:"Obrigado pela sua mensagem. Entraremos em contato em breve.",
      contact_modal_message_failed:"Não houve resposta do servidor. Tente mais tarde",
      contact_modal_message_button:"Aceitar",
      portfolio_main_caption: "Meu portfolio",
      portfolio_caption1: "Esta página web",
      portfolio_text11: "Se você quiser saber como esse site é construído, vou te mostrar. Eu não guardo nada.",
      portfolio_text12: "Aqui eu uso estas tecnologias:",
      portfolio_text13: "Pode ver o código fonte",
      portfolio_text14: "aqui",
      portfolio_caption2: "Dojo de Karatê",
      portfolio_text21: "Site estático de um dojo de Karatê. Os detalhes dessa academia são fantasia, então nem pense em se inscrever!!!",
      portfolio_text22: "Estas são as tecnologias que utilizo neste site:",
      portfolio_text23: "E se você quiser ver o código fonte, pode clicar ",
      portfolio_text24: "aqui",
      portfolio_caption3: "Empresa de encomendas falsas",
      portfolio_text31: "Se você tiver que enviar um pedido e souber suas medidas e valor declarado, você insere todos esses dados na página e...Voilá!!! O site informará quanto você terá que pagar pelo frete. Você pode até pagar com Mercado Pago. Claro, já que o site é um site de teste...Não insira seus dados reais na hora de pagar!!!",
      portfolio_text32: "Aliás, o site foi construído com estas tecnologias:",
      portfolio_text33: "E se você quiser ver o código fonte, pode clicar ",
      portfolio_text34: "aqui",
      portfolio_caption4: "Site de comércio eletrônico com React e Firebase",
      portfolio_text41: "Compumaxx, uma empresa de suprimentos de TI que existe apenas em nosso repositório de código, possui este site de comércio eletrônico baseado em React.JS e usa um banco de dados baseado em Firebase.",
      portfolio_text42: "Estas são as tecnologias que utilizo neste site:",
      portfolio_text43: "Compumaxx oferece ",
      portfolio_text44: "aqui",
      portfolio_text45: " seu código-fonte.",
      portfolio_caption5: "Site de comércio eletrônico desde o servidor",
      portfolio_text51: "Outro exemplo de site de comércio eletrônico simples... mas seguro. Seu design é tão minimalista que não possui nenhum estilo decorativo. Mas por outro lado, quem quiser acessá-lo deverá se cadastrar com nome de usuário e senha. É baseado em Express.JS e seus dados são armazenados em um banco de dados feito em MongoDB. Também possui um chat interativo entre clientes e administradores. Ele também usa cookies",
      portfolio_text52: "Para que funcionasse, foram utilizadas todas estas tecnologias:",
      portfolio_text53: "Se você está curioso para saber como é o código, basta clicar ",
      portfolio_text54: "aqui"
    }
  };
  
  export default translations;